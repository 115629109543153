define("ember-media/components/-private/media-query-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MediaQueryList {
    constructor(targetWindow, mediaQuery, listener) {
      this.nativeMediaQueryList = targetWindow.matchMedia(mediaQuery);
      this.active = true;
      this.listener = (...args) => {
        this.matches = this.nativeMediaQueryList.matches;
        if (this.active) {
          listener(...args);
        }
      };
      this.nativeMediaQueryList.addListener(this.listener);
      this.matches = this.nativeMediaQueryList.matches;
    }
    cancel() {
      this.active = false;
      this.nativeMediaQueryList.removeListener(this.listener);
    }
  }
  _exports.default = MediaQueryList;
});