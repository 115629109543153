define("ember-svg-jar/inlined/verve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\"><rect fill=\"#00425F\" width=\"750\" height=\"471\" rx=\"40\"/><circle fill=\"#EE312A\" cx=\"156.263\" cy=\"215.5\" r=\"115.263\"/><path d=\"M156.263 264.873c-25.78-58.441-44.684-113.033-44.684-113.033H72.054s24.057 70.072 68.742 157.7h30.935c44.685-87.628 68.742-157.7 68.742-157.7h-39.525s-18.905 54.592-44.685 113.033zm551.782-7.267h-77.329s1.718 25.78 36.087 25.78c17.184 0 34.369-5.16 34.369-5.16l3.437 27.495s-17.185 6.874-41.243 6.874c-34.368 0-65.3-17.185-65.3-65.3 0-37.805 24.058-61.863 58.427-61.863 51.552 0 54.99 51.553 51.552 72.174zm-53.27-48.116c-22.34 0-24.059 24.057-24.059 24.057h48.116s-1.718-24.057-24.057-24.057zm-212.441 7.258l5.155-27.495s-39.813-12.081-72.174 10.31v109.978h34.37l-.002-89.356c13.746-10.31 32.65-3.437 32.65-3.437zm-93.918 40.858h-77.329s1.718 25.78 36.087 25.78c17.184 0 34.367-5.16 34.367-5.16l3.438 27.495s-17.184 6.874-41.242 6.874c-34.369 0-65.3-17.185-65.3-65.3 0-37.805 24.058-61.863 58.427-61.863 51.552 0 54.988 51.553 51.552 72.174zm-53.272-48.116c-22.339 0-24.057 24.057-24.057 24.057h48.116s-1.718-24.057-24.059-24.057zm230.66 58.834a534.672 534.672 0 01-25.777-80.102l-34.366.005s17.184 66.345 46.399 121.32h27.487c29.215-54.975 46.399-121.308 46.399-121.308H551.58a534.82 534.82 0 01-25.776 80.085z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "750",
      "height": "471",
      "viewBox": "0 0 750 471",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});