define("ember-svg-jar/inlined/amex_logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<style>.st0{fill:#0071ce}</style><path class=\"st0\" d=\"M59.4 49.7l-2.6-2.9-2.7 2.9H37.4V36.2h-5.3l6.6-15.1h6.4l2.3 5.2v-5.2h8l1.4 3.9 1.4-3.9h6.1v-2.3c0-1.4-1.1-2.5-2.5-2.5H8.2c-1.4 0-2.5 1.1-2.5 2.5v32.5c0 1.4 1.1 2.5 2.5 2.5h53.6c1.4 0 2.5-1.1 2.5-2.5v-1.6h-4.9z\"/><path class=\"st0\" d=\"M60.1 48.1h4.2l-5.5-5.9 5.5-5.8h-4.1l-3.5 3.8-3.4-3.8h-4.2l5.6 5.9-5.6 5.8h4.1l3.5-3.8 3.4 3.8z\"/><path class=\"st0\" d=\"M42.4 45.4v-1.8H49v-2.7h-6.6v-1.8h6.7v-2.7h-9.9v11.7h9.9v-2.7h-6.7zM61.2 34.7h3V22.9l-4.8.1-2.6 7.3-2.7-7.3h-5v11.7h3.2v-8.2l3 8.2h2.8l3.1-8.2v8.2z\"/><path class=\"st0\" d=\"M44 23h-4.1l-5.2 11.7h3.5l1-2.3h5.4l1 2.3h3.6L44 23zm-3.7 6.7l1.6-3.8 1.6 3.8h-3.2zM61.4 41.9l2.9 3.2v-6.4l-2.9 3.2z\"/>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1__x28_1:1_x29_",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 70 70",
      "xml:space": "preserve"
    }
  };
});