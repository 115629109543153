define("ember-svg-jar/inlined/arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M11.773 66.371l9.563-9c.435-.42.485-1.1.044-1.59-.394-.438-1.163-.449-1.591-.045l-7.664 7.225V1.813a1.124 1.124 0 10-2.25 0V62.96L2.21 55.736c-.429-.404-1.178-.375-1.591.044-.435.444-.398 1.178.044 1.591l9.562 9c.26.264.51.312.774.316.26 0 .606-.158.773-.316z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 22 67"
    }
  };
});