define("ember-svg-jar/inlined/suitcase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\"4\" y=\"8\" width=\"16\" height=\"11\" rx=\"2\" stroke=\"currentColor\" stroke-width=\"2\"/><path d=\"M10 7V6a1 1 0 011-1h2a1 1 0 011 1v1M8 9v10m8-10v10\" stroke=\"currentColor\" stroke-width=\"2\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});