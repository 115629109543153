define("ember-svg-jar/inlined/circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M6 12A6 6 0 116 0a6 6 0 010 12zm.053-2.464c1.994 0 3.61-1.607 3.61-3.59 0-1.982-1.616-3.589-3.61-3.589-1.994 0-3.61 1.607-3.61 3.59 0 1.982 1.616 3.589 3.61 3.589z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12"
    }
  };
});