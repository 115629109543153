define("@warp-drive/core-types/request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SkipCache = _exports.STRUCTURED = _exports.IS_FUTURE = _exports.EnableHydration = void 0;
  const SkipCache = _exports.SkipCache = Symbol.for('wd:skip-cache');
  const EnableHydration = _exports.EnableHydration = Symbol.for('wd:enable-hydration');
  const IS_FUTURE = _exports.IS_FUTURE = Symbol('IS_FUTURE');
  const STRUCTURED = _exports.STRUCTURED = Symbol('DOC');

  /**
   * Use these options to adjust CacheHandler behavior for a request.
   *
   * @typedoc
   */

  /**
   * JavaScript's native Request class.
   *
   * EmberData provides our own typings due to incompleteness in the native typings.
   *
   * @typedoc
   */

  /**
   * Extends JavaScript's native {@link Request} object with additional
   * properties specific to the RequestManager's capabilities.
   *
   * @typedoc
   */

  /**
   * Immutable version of {@link RequestInfo}. This is what is passed to handlers.
   *
   * @typedoc
   */
});