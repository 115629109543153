define("@ember-data/model/index", ["exports", "@ember-data/model/has-many-PdQBns8a", "@ember-data/model/model-YsOraZ6y", "@ember-data/model/hooks-dXmQbIOF"], function (_exports, _hasManyPdQBns8a, _modelYsOraZ6y, _hooksDXmQbIOF) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "ModelSchemaProvider", {
    enumerable: true,
    get: function () {
      return _hooksDXmQbIOF.M;
    }
  });
  Object.defineProperty(_exports, "attr", {
    enumerable: true,
    get: function () {
      return _hasManyPdQBns8a.a;
    }
  });
  Object.defineProperty(_exports, "belongsTo", {
    enumerable: true,
    get: function () {
      return _hasManyPdQBns8a.b;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _modelYsOraZ6y.M;
    }
  });
  Object.defineProperty(_exports, "hasMany", {
    enumerable: true,
    get: function () {
      return _hasManyPdQBns8a.h;
    }
  });
  Object.defineProperty(_exports, "instantiateRecord", {
    enumerable: true,
    get: function () {
      return _hooksDXmQbIOF.i;
    }
  });
  Object.defineProperty(_exports, "modelFor", {
    enumerable: true,
    get: function () {
      return _hooksDXmQbIOF.m;
    }
  });
  Object.defineProperty(_exports, "teardownRecord", {
    enumerable: true,
    get: function () {
      return _hooksDXmQbIOF.t;
    }
  });
});