define("ember-svg-jar/inlined/warning-black", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#32313C\" cx=\"18\" cy=\"18\" r=\"18\"/><path d=\"M16.192 10h3.616a1 1 0 01.985 1.174l-1.59 9a1 1 0 01-.985.826h-.436a1 1 0 01-.985-.826l-1.59-9A1 1 0 0116.192 10zM18 26c-1.101 0-2-.899-2-2 0-1.124.899-2 2-2 1.124 0 2 .876 2 2 0 1.101-.876 2-2 2z\" fill=\"#FFF\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});