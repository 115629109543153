define("ember-svg-jar/inlined/user-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.866 21.979a6.023 6.023 0 014.286-1.797h9.697c1.607 0 3.148.646 4.285 1.797a6.175 6.175 0 011.775 4.339v2.454A1.22 1.22 0 0121.697 30a1.22 1.22 0 01-1.212-1.227v-2.455c0-.977-.383-1.913-1.065-2.603a3.614 3.614 0 00-2.572-1.079H7.153c-.965 0-1.89.388-2.572 1.079a3.705 3.705 0 00-1.065 2.603v2.454A1.22 1.22 0 012.303 30a1.22 1.22 0 01-1.212-1.227v-2.455c0-1.628.638-3.188 1.775-4.34zM12 8.4c-2.169 0-3.927 1.612-3.927 3.6S9.83 15.6 12 15.6s3.927-1.612 3.927-3.6S14.17 8.4 12 8.4zM5.455 12c0-3.314 2.93-6 6.545-6 3.615 0 6.546 2.686 6.546 6S15.614 18 12 18s-6.545-2.686-6.545-6z\" fill=\"currentColor\"/><path d=\"M21.5 1v5M24 3.5h-5\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\"/>",
    "attrs": {
      "width": "25",
      "height": "30",
      "viewBox": "0 0 25 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});