define("ember-svg-jar/inlined/arrow-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.78 9.97l-1.06 1.06L.69 6 5.72.97l1.06 1.06L2.81 6l3.97 3.97z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "7",
      "height": "12",
      "viewBox": "0 0 7 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});