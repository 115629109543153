define("ember-svg-jar/inlined/fueltype-el", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path stroke-width=\"2\" stroke=\"currentColor\" d=\"M34.26 33.647l16.971 10.987-2.063 7.594a1 1 0 00.394 1.083l8.348 5.803a1 1 0 00.032.022 1 1 0 001.381-.305l11.347-17.77a1 1 0 00-.303-1.38l-28.25-18.1a1 1 0 00-1.377.295l-6.774 10.385-.001.003a1 1 0 00.295 1.383z\"/><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" stroke=\"currentColor\" d=\"M65.164 51.527l7.563 4.828v24.239\"/><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" stroke=\"currentColor\" d=\"M27.387 39.017l-2.062 7.402h2.86l-6.49 14.808.618-12.052H20l.94-10.158z\"/><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" stroke=\"currentColor\" d=\"M38.121 24.319l-3.666-2.38M35.41 28.494l-3.666-2.38\"/><path stroke-width=\"1.5\" stroke=\"currentColor\" d=\"M60.132 37.69a1.25 1.25 0 00-.213-.106 1.25 1.25 0 00-1.616.717l-4.62 12a1.25 1.25 0 00.474 1.49l2.07 1.38.032.02a1.25 1.25 0 001.722-.398l6.73-10.768a1.25 1.25 0 00-.399-1.723l-4.18-2.612z\"/><path stroke-linecap=\"square\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M58.156 58.5L70.5 40.289\"/><path stroke-linecap=\"square\" stroke-linejoin=\"round\" stroke-width=\"1.5\" stroke=\"currentColor\" d=\"M41.034 36.13l6.466-9.594M45.19 38.994l7.05-10.288\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
});