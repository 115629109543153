define("ember-svg-jar/inlined/extra_package", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 5.94c0 2.566-3.668 7.27-5.269 9.2a.945.945 0 01-1.462 0C3.669 13.21 0 8.505 0 5.94 0 2.66 2.686 0 6 0s6 2.66 6 5.94zM6 2.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7zM3.5 6a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0z\" fill=\"currentColor\"/><path d=\"M3.769 18.824h18.462\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-dasharray=\"2 3\"/><path d=\"M17 9h6M20 6v6\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "20",
      "viewBox": "0 0 24 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});