define("@ember-data/store/-private", ["exports", "@ember-data/store/cache-handler-XLbbNJdo"], function (_exports, _cacheHandlerXLbbNJdo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "ARRAY_SIGNAL", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.A;
    }
  });
  Object.defineProperty(_exports, "AdapterPopulatedRecordArray", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.f;
    }
  });
  Object.defineProperty(_exports, "CacheHandler", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.C;
    }
  });
  Object.defineProperty(_exports, "IdentifierArray", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.I;
    }
  });
  Object.defineProperty(_exports, "MUTATE", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.M;
    }
  });
  Object.defineProperty(_exports, "RecordArray", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.I;
    }
  });
  Object.defineProperty(_exports, "RecordArrayManager", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.R;
    }
  });
  Object.defineProperty(_exports, "SOURCE", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.g;
    }
  });
  Object.defineProperty(_exports, "Store", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.S;
    }
  });
  Object.defineProperty(_exports, "StoreMap", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.l;
    }
  });
  Object.defineProperty(_exports, "_clearCaches", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo._;
    }
  });
  Object.defineProperty(_exports, "_deprecatingNormalize", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.o;
    }
  });
  Object.defineProperty(_exports, "coerceId", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.e;
    }
  });
  Object.defineProperty(_exports, "fastPush", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.h;
    }
  });
  Object.defineProperty(_exports, "isStableIdentifier", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.i;
    }
  });
  Object.defineProperty(_exports, "notifyArray", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.n;
    }
  });
  Object.defineProperty(_exports, "peekCache", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.p;
    }
  });
  Object.defineProperty(_exports, "recordIdentifierFor", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.r;
    }
  });
  Object.defineProperty(_exports, "removeRecordDataFor", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.j;
    }
  });
  Object.defineProperty(_exports, "setCacheFor", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.m;
    }
  });
  Object.defineProperty(_exports, "setIdentifierForgetMethod", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.c;
    }
  });
  Object.defineProperty(_exports, "setIdentifierGenerationMethod", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.a;
    }
  });
  Object.defineProperty(_exports, "setIdentifierResetMethod", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.d;
    }
  });
  Object.defineProperty(_exports, "setIdentifierUpdateMethod", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.b;
    }
  });
  Object.defineProperty(_exports, "setRecordIdentifier", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.k;
    }
  });
  Object.defineProperty(_exports, "storeFor", {
    enumerable: true,
    get: function () {
      return _cacheHandlerXLbbNJdo.s;
    }
  });
});