define("ember-svg-jar/inlined/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.023 13.04a4.6 4.6 0 013.273-1.373h7.408a4.6 4.6 0 013.273 1.373 4.717 4.717 0 011.356 3.314v1.875a.932.932 0 01-.925.938.932.932 0 01-.926-.938v-1.875a2.83 2.83 0 00-.814-1.989 2.76 2.76 0 00-1.964-.823H6.296a2.76 2.76 0 00-1.964.823 2.83 2.83 0 00-.813 1.99v1.874a.932.932 0 01-.926.938.932.932 0 01-.926-.938v-1.875c0-1.243.488-2.435 1.356-3.314zM10 2.667c-1.657 0-3 1.23-3 2.75 0 1.518 1.343 2.75 3 2.75s3-1.232 3-2.75c0-1.52-1.343-2.75-3-2.75zm-5 2.75C5 2.885 7.239.833 10 .833s5 2.052 5 4.584C15 7.948 12.761 10 10 10S5 7.948 5 5.417z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});