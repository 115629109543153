define("ember-svg-jar/inlined/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 9a1 1 0 011-1h15.5a1 1 0 110 2H4a1 1 0 01-1-1zm2.5 3.5a1 1 0 011-1H8a1 1 0 110 2H6.5a1 1 0 01-1-1zm8.5 0a1 1 0 011-1h1.5a1 1 0 110 2H15a1 1 0 01-1-1zm-8.5 4a1 1 0 011-1H8a1 1 0 110 2H6.5a1 1 0 01-1-1zm4.5 0a1 1 0 011-1h1.5a1 1 0 110 2H11a1 1 0 01-1-1z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17 5H7a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2zM7 3a4 4 0 00-4 4v10a4 4 0 004 4h10a4 4 0 004-4V7a4 4 0 00-4-4H7z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});