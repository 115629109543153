define("ember-svg-jar/inlined/disc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"25\" cy=\"25\" r=\"25\"/>",
    "attrs": {
      "width": ".6em",
      "height": ".6em",
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});