define("ember-svg-jar/inlined/no-contract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>ingenbinding</title><g stroke=\"currentColor\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M19.5 10h6a5.5 5.5 0 010 11h-6a5.5 5.5 0 01-5.5-5.5\"/><path d=\"M12.5 21h-6a5.5 5.5 0 010-11h6a5.5 5.5 0 015.5 5.5M16 5.5v-5M18 7l2.733-4M13.733 7L11 3\"/></g>",
    "attrs": {
      "width": "32",
      "height": "22",
      "viewBox": "0 0 32 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});