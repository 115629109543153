define("ember-svg-jar/inlined/award", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0)\" stroke=\"currentColor\" stroke-width=\"2.295\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M15.5 19.151a8.568 8.568 0 100-17.135 8.568 8.568 0 000 17.135z\"/><path d=\"M10.862 17.793L9.38 28.943l6.12-3.672 6.12 3.672L20.14 17.78\"/></g><defs><clipPath id=\"clip0\"><path fill=\"currentColor\" transform=\"translate(.813 .792)\" d=\"M0 0h29.375v29.375H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "31",
      "height": "31",
      "viewBox": "0 0 31 31",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});