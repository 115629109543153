define("ember-svg-jar/inlined/smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.583 21.083H6.417c-1.559 0-2.75-1.191-2.75-2.75V3.667c0-1.559 1.191-2.75 2.75-2.75h9.166c1.559 0 2.75 1.191 2.75 2.75v14.666c0 1.559-1.191 2.75-2.75 2.75zM6.417 2.75c-.55 0-.917.367-.917.917v14.666c0 .55.367.917.917.917h9.166c.55 0 .917-.367.917-.917V3.667c0-.55-.367-.917-.917-.917H6.417z\" fill=\"currentColor\"/><path d=\"M11 17.417c-.092 0-.275 0-.367-.092-.091 0-.183-.092-.275-.183a.832.832 0 01-.275-.642c0-.092 0-.275.092-.367.092-.091.092-.183.183-.275a.886.886 0 011.284 0 .99.99 0 01.275.642.832.832 0 01-.275.642.832.832 0 01-.642.275z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});