define("ember-svg-jar/inlined/fueltype-petrol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke=\"currentColor\"><path stroke-width=\"2\" d=\"M56.171 51.013l7.273 4.723a.999.999 0 00.006.004 1 1 0 001.381-.304l9.145-14.322.004-.007a1 1 0 00-.315-1.379L53.643 27.165a1 1 0 00-1.369.3l-5.846 8.963 13.59 8.313-3.847 6.272z\"/><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M54.871 42.304l-1.163 2.93-1.97 1.16\"/><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M50.75 38.992c-2.813 3.266-3.9 7.6-.673 10.14 2.152 1.695 4.636 1.42 7.453-.824\"/><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M22.847 46.13a4.847 4.847 0 004.848-4.848c0-1.785-1.616-4.15-4.848-7.094C19.616 37.133 18 39.498 18 41.282a4.847 4.847 0 004.847 4.847z\"/><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M50.077 29.294L41.989 25.5H24.142l-.064 3.24 16.834.555 7.378 3.792\"/><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M71.641 44.735l9.576 6.208v24.24h-6.444V54.73l-6.48-4.12\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 100"
    }
  };
});