define("@ember-data/model/-private", ["exports", "@ember-data/model/has-many-PdQBns8a", "@ember-data/model/model-YsOraZ6y"], function (_exports, _hasManyPdQBns8a, _modelYsOraZ6y) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Errors", {
    enumerable: true,
    get: function () {
      return _modelYsOraZ6y.E;
    }
  });
  Object.defineProperty(_exports, "LEGACY_SUPPORT", {
    enumerable: true,
    get: function () {
      return _modelYsOraZ6y.L;
    }
  });
  Object.defineProperty(_exports, "ManyArray", {
    enumerable: true,
    get: function () {
      return _modelYsOraZ6y.R;
    }
  });
  Object.defineProperty(_exports, "Model", {
    enumerable: true,
    get: function () {
      return _modelYsOraZ6y.M;
    }
  });
  Object.defineProperty(_exports, "PromiseBelongsTo", {
    enumerable: true,
    get: function () {
      return _modelYsOraZ6y.P;
    }
  });
  Object.defineProperty(_exports, "PromiseManyArray", {
    enumerable: true,
    get: function () {
      return _modelYsOraZ6y.a;
    }
  });
  Object.defineProperty(_exports, "attr", {
    enumerable: true,
    get: function () {
      return _hasManyPdQBns8a.a;
    }
  });
  Object.defineProperty(_exports, "belongsTo", {
    enumerable: true,
    get: function () {
      return _hasManyPdQBns8a.b;
    }
  });
  Object.defineProperty(_exports, "hasMany", {
    enumerable: true,
    get: function () {
      return _hasManyPdQBns8a.h;
    }
  });
  Object.defineProperty(_exports, "lookupLegacySupport", {
    enumerable: true,
    get: function () {
      return _modelYsOraZ6y.l;
    }
  });
});