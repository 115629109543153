define("@ember-data/model/hooks-dXmQbIOF", ["exports", "@ember/application", "@ember/debug", "@ember-data/store/-private", "@ember-data/model/model-YsOraZ6y", "@ember-data/model/util-3DHZJC9h"], function (_exports, _application, _debug, _private, _modelYsOraZ6y, _util3DHZJC9h) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.M = void 0;
  _exports.b = buildSchema;
  _exports.i = instantiateRecord;
  _exports.m = modelFor;
  _exports.t = teardownRecord;
  /*
      In case someone defined a relationship to a mixin, for example:
      ```ts
        class CommentModel extends Model {
          @belongsTo('commentable', { polymorphic: true }) owner;
        }
  
        let Commentable = Mixin.create({
          @hasMany('comment') comments;
        });
      ```
      we want to look up a Commentable class which has all the necessary
      relationship meta data. Thus, we look up the mixin and create a mock
      Model, so we can access the relationship CPs of the mixin (`comments`)
      in this case
    */
  function modelForMixin(store, normalizedModelName) {
    const owner = (0, _application.getOwner)(store);
    const MaybeMixin = owner.factoryFor(`mixin:${normalizedModelName}`);
    const mixin = MaybeMixin && MaybeMixin.class;
    if (mixin) {
      const ModelForMixin = _modelYsOraZ6y.M.extend(mixin);
      ModelForMixin.__isMixin = true;
      ModelForMixin.__mixin = mixin;
      //Cache the class as a model
      owner.register(`model:${normalizedModelName}`, ModelForMixin);
    }
    return owner.factoryFor(`model:${normalizedModelName}`);
  }
  class ModelSchemaProvider {
    constructor(store) {
      this.store = store;
      this._relationshipsDefCache = Object.create(null);
      this._attributesDefCache = Object.create(null);
      this._fieldsDefCache = Object.create(null);
    }
    fields(identifier) {
      const {
        type
      } = identifier;
      let fieldDefs = this._fieldsDefCache[type];
      if (fieldDefs === undefined) {
        fieldDefs = new Map();
        this._fieldsDefCache[type] = fieldDefs;
        const attributes = this.attributesDefinitionFor(identifier);
        const relationships = this.relationshipsDefinitionFor(identifier);
        for (const attr of Object.values(attributes)) {
          fieldDefs.set(attr.name, attr);
        }
        for (const rel of Object.values(relationships)) {
          fieldDefs.set(rel.name, rel);
        }
      }
      return fieldDefs;
    }

    // Following the existing RD implementation
    attributesDefinitionFor(identifier) {
      const {
        type
      } = identifier;
      let attributes;
      attributes = this._attributesDefCache[type];
      if (attributes === undefined) {
        const modelClass = this.store.modelFor(type);
        const attributeMap = modelClass.attributes;
        attributes = Object.create(null);
        attributeMap.forEach((meta, name) => attributes[name] = meta);
        this._attributesDefCache[type] = attributes;
      }
      return attributes;
    }

    // Following the existing RD implementation
    relationshipsDefinitionFor(identifier) {
      const {
        type
      } = identifier;
      let relationships;
      relationships = this._relationshipsDefCache[type];
      if (relationships === undefined) {
        const modelClass = this.store.modelFor(type);
        relationships = modelClass.relationshipsObject || null;
        this._relationshipsDefCache[type] = relationships;
      }
      return relationships;
    }
    doesTypeExist(modelName) {
      const type = (0, _util3DHZJC9h.n)(modelName);
      const factory = getModelFactory(this.store, type);
      return factory !== null;
    }
  }
  _exports.M = ModelSchemaProvider;
  function buildSchema(store) {
    return new ModelSchemaProvider(store);
  }
  function getModelFactory(store, type) {
    if (!store._modelFactoryCache) {
      store._modelFactoryCache = Object.create(null);
    }
    const cache = store._modelFactoryCache;
    let factory = cache[type];
    if (!factory) {
      const owner = (0, _application.getOwner)(store);
      factory = owner.factoryFor(`model:${type}`);
      if (!factory) {
        //Support looking up mixins as base types for polymorphic relationships
        factory = modelForMixin(store, type);
      }
      if (!factory) {
        // we don't cache misses in case someone wants to register a missing model
        return null;
      }
      const klass = factory.class;
      if (klass.isModel) {
        const hasOwnModelNameSet = klass.modelName && Object.prototype.hasOwnProperty.call(klass, 'modelName');
        if (!hasOwnModelNameSet) {
          Object.defineProperty(klass, 'modelName', {
            value: type
          });
        }
      }
      cache[type] = factory;
    }
    return factory;
  }
  function instantiateRecord(identifier, createRecordArgs) {
    const type = identifier.type;
    const cache = this.cache;
    // TODO deprecate allowing unknown args setting
    const createOptions = {
      _createProps: createRecordArgs,
      // TODO @deprecate consider deprecating accessing record properties during init which the below is necessary for
      _secretInit: {
        identifier,
        cache,
        store: this,
        cb: secretInit
      }
    };

    // ensure that `getOwner(this)` works inside a model instance
    (0, _application.setOwner)(createOptions, (0, _application.getOwner)(this));
    const factory = getModelFactory(this, type);
    (false && !(factory) && (0, _debug.assert)(`No model was found for '${type}'`, factory));
    return factory.class.create(createOptions);
  }
  function teardownRecord(record) {
    (false && !('destroy' in record) && (0, _debug.assert)(`expected to receive an instance of Model from @ember-data/model. If using a custom model make sure you implement teardownRecord`, 'destroy' in record));
    record.destroy();
  }
  function modelFor(modelName) {
    (false && !(!this.isDestroyed && !this.isDestroying) && (0, _debug.assert)(`Attempted to call store.modelFor(), but the store instance has already been destroyed.`, !this.isDestroyed && !this.isDestroying));
    (false && !(modelName) && (0, _debug.assert)(`You need to pass a model name to the store's modelFor method`, modelName));
    (false && !(typeof modelName === 'string' && modelName.length) && (0, _debug.assert)(`Please pass a proper model name to the store's modelFor method`, typeof modelName === 'string' && modelName.length));
    const type = (0, _util3DHZJC9h.n)(modelName);
    const maybeFactory = getModelFactory(this, type);
    const klass = maybeFactory && maybeFactory.class ? maybeFactory.class : null;
    const ignoreType = !klass || !klass.isModel || this._forceShim;
    if (!ignoreType) {
      return klass;
    }
    (false && !(this.getSchemaDefinitionService().doesTypeExist(type)) && (0, _debug.assert)(`No model was found for '${type}' and no schema handles the type`, this.getSchemaDefinitionService().doesTypeExist(type)));
  }
  function secretInit(record, cache, identifier, store) {
    (0, _private.setRecordIdentifier)(record, identifier);
    _private.StoreMap.set(record, store);
    (0, _private.setCacheFor)(record, cache);
  }
});