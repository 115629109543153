define("ember-svg-jar/inlined/calendar_inverted", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.24 4.5h9.135a2.74 2.74 0 012.74 2.74v9.135a2.74 2.74 0 01-2.74 2.74H7.24a2.74 2.74 0 01-2.74-2.74V7.24A2.74 2.74 0 017.24 4.5z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.24 5.441a1.8 1.8 0 00-1.799 1.8v9.134a1.8 1.8 0 001.8 1.799h9.134a1.8 1.8 0 001.799-1.8V7.24a1.8 1.8 0 00-1.8-1.799H7.24zm-3.681 1.8A3.682 3.682 0 017.24 3.558h9.135a3.682 3.682 0 013.681 3.681v9.135a3.682 3.682 0 01-3.681 3.681H7.24a3.682 3.682 0 01-3.681-3.681V7.24z\" fill=\"#29194A\"/><path clip-rule=\"evenodd\" d=\"M3.559 7.545h16.497v1.882H3.56V7.545z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.064 16.402c0-.65.526-1.176 1.176-1.176h.914a1.176 1.176 0 110 2.353H7.24c-.65 0-1.176-.527-1.176-1.177zm0-3.654c0-.65.526-1.176 1.176-1.176h.914a1.176 1.176 0 110 2.353H7.24c-.65 0-1.176-.527-1.176-1.177zm4.566 3.654c0-.65.528-1.176 1.177-1.176h.914a1.176 1.176 0 110 2.353h-.914c-.65 0-1.176-.527-1.176-1.177zm3.655-3.654c0-.65.526-1.176 1.176-1.176h.914a1.176 1.176 0 110 2.353h-.914c-.65 0-1.176-.527-1.176-1.177z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none",
      "width": "24",
      "height": "24",
      "class": "shrink-0"
    }
  };
});