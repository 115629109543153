define("ember-svg-jar/inlined/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.567 4c-4.47 0-8.094 3.582-8.094 8s3.624 8 8.094 8c4.47 0 8.095-3.582 8.095-8s-3.624-8-8.095-8zM2.45 12c0-5.523 4.53-10 10.118-10s10.118 4.477 10.118 10-4.53 10-10.118 10S2.45 17.523 2.45 12z\" fill=\"currentColor\"/><path d=\"M12.566 7c-.558 0-1.011.448-1.011 1s.453 1 1.011 1c.56 0 1.012-.448 1.012-1s-.453-1-1.011-1zM13.578 12c0-.552-.453-1-1.011-1-.56 0-1.012.448-1.012 1v4c0 .552.453 1 1.011 1 .56 0 1.012-.448 1.012-1v-4z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "25",
      "height": "24",
      "viewBox": "0 0 25 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});