define("ember-svg-jar/inlined/drivetrain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 5V1a1 1 0 00-1-1H1a1 1 0 00-1 1v4a1 1 0 001 1h1a1 1 0 001-1zM3 17v-4a1 1 0 00-1-1H1a1 1 0 00-1 1v4a1 1 0 001 1h1a1 1 0 001-1zM14 5V1a1 1 0 00-1-1h-1a1 1 0 00-1 1v4a1 1 0 001 1h1a1 1 0 001-1zM14 17v-4a1 1 0 00-1-1h-1a1 1 0 00-1 1v4a1 1 0 001 1h1a1 1 0 001-1z\" fill=\"currentColor\"/><path d=\"M5.5 3a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0zM5.5 15a1.5 1.5 0 103 0 1.5 1.5 0 00-3 0z\" stroke=\"currentColor\"/><path d=\"M11 15H9M5 15H3M5 3H3M11 3H9M7 13.5v-9\" stroke=\"currentColor\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "14",
      "height": "18",
      "viewBox": "0 0 14 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});