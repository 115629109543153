define("@ember-data/adapter/-private", ["exports", "@ember-data/adapter/serialize-into-hash-2lQbHRKN", "@ember-data/adapter/build-url-mixin-AgfAmsW-"], function (_exports, _serializeIntoHash2lQbHRKN, _buildUrlMixinAgfAmsW) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "BuildURLMixin", {
    enumerable: true,
    get: function () {
      return _buildUrlMixinAgfAmsW.B;
    }
  });
  Object.defineProperty(_exports, "determineBodyPromise", {
    enumerable: true,
    get: function () {
      return _serializeIntoHash2lQbHRKN.d;
    }
  });
  Object.defineProperty(_exports, "fetch", {
    enumerable: true,
    get: function () {
      return _serializeIntoHash2lQbHRKN.g;
    }
  });
  Object.defineProperty(_exports, "parseResponseHeaders", {
    enumerable: true,
    get: function () {
      return _serializeIntoHash2lQbHRKN.p;
    }
  });
  Object.defineProperty(_exports, "serializeIntoHash", {
    enumerable: true,
    get: function () {
      return _serializeIntoHash2lQbHRKN.b;
    }
  });
  Object.defineProperty(_exports, "serializeQueryParams", {
    enumerable: true,
    get: function () {
      return _serializeIntoHash2lQbHRKN.s;
    }
  });
  Object.defineProperty(_exports, "setupFastboot", {
    enumerable: true,
    get: function () {
      return _serializeIntoHash2lQbHRKN.a;
    }
  });
});