define("ember-svg-jar/inlined/battery_charging_updated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6 6a2 2 0 00-2 2v8a2 2 0 002 2h10a2 2 0 002-2v-1a1 1 0 01.553-.894l.894-.448a1 1 0 00.553-.894v-1.528a1 1 0 00-.553-.894l-.894-.448A1 1 0 0118 9V8a2 2 0 00-2-2H6zM2 8a4 4 0 014-4h10a4 4 0 014 4v.382l.342.17A3 3 0 0122 11.237v1.528a3 3 0 01-1.658 2.683l-.342.171V16a4 4 0 01-4 4H6a4 4 0 01-4-4V8z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.555 8.168a1 1 0 01.277 1.387L10.868 11H13a1 1 0 01.832 1.555l-2 3a1 1 0 01-1.664-1.11L11.132 13H9a1 1 0 01-.832-1.555l2-3a1 1 0 011.387-.277z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});