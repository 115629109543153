define("@ember-data/adapter/index", ["exports", "@ember-data/adapter/index-4RESM7Oz", "@ember-data/adapter/build-url-mixin-AgfAmsW-"], function (_exports, _index4RESM7Oz, _buildUrlMixinAgfAmsW) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "BuildURLMixin", {
    enumerable: true,
    get: function () {
      return _buildUrlMixinAgfAmsW.B;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _index4RESM7Oz.A;
    }
  });
});