define("ember-svg-jar/inlined/app_icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_2880_2601)\"><path d=\"M97.5 0h-72C11.693 0 .5 11.193.5 25v72c0 13.807 11.193 25 25 25h72c13.807 0 25-11.193 25-25V25c0-13.807-11.193-25-25-25z\" fill=\"#29194A\"/><g clip-path=\"url(#clip1_2880_2601)\"><path d=\"M106.299 74.87a3.214 3.214 0 100-6.428 3.214 3.214 0 000 6.428z\" fill=\"#FF4A7E\"/><path d=\"M38.798 47.529h-4.429v26.956h4.429V47.529zM51.728 55.23a9.861 9.861 0 00-9.82 9.82c0 5.391 4.429 9.82 10.012 9.82 5.392 0 7.895-3.274 7.895-3.274l-2.503-3.119s-1.926 2.35-5.199 2.35c-3.08 0-4.928-1.926-5.391-4.044h14.056c.126-.7.19-1.408.192-2.118 0-5.199-3.85-9.435-9.242-9.435zm-5.006 7.894c.577-2.118 2.118-3.651 4.813-3.651 2.504 0 4.044 1.54 4.622 3.651h-9.435zM31.167 56.087a16.93 16.93 0 000-2.087c-.332-3.834-3.1-6.943-6.928-6.943A6.954 6.954 0 0017.292 54v5.456h-3.72v3.766h3.72v11.263h4.367V63.222h9.526v-3.766H21.66v-5.358a2.596 2.596 0 012.58-2.596c1.42 0 2.49 1.283 2.577 2.596.065.872.017 1.992.017 1.992l4.334-.003zM98.67 65.128c-2.336-2.987-4.979-6-7.942-9.668h-4.406c-.102 3.983-.83 7.87-2.04 10.77-1.12 2.69-2.56 4.457-3.853 4.457-2.525 0-3.677-2.92-5.793-6.65 2.656-2.721 5.093-6.076 6.142-8.577H76c-1.36 2.521-4.911 6.491-7.489 8.133V47.529h-4.447v26.958h4.45v-5.455a19.66 19.66 0 002.872-2.138c2.218 3.53 4.203 7.87 9.046 7.87 3.154 0 5.913-2.452 7.769-6.902.811-1.948 1.306-4.452 1.71-6.923 2.13 2.584 3.628 4.33 4.995 6.203.59.805.566 1.965.048 2.593-.87 1.05-2.639 1.4-4.667.38L88.335 73.6a8.112 8.112 0 004.414 1.343c2.602 0 4.584-1.11 5.71-2.428 1.772-2.08 2.01-5.093.212-7.387z\" fill=\"#fff\"/></g></g><defs><clipPath id=\"clip0_2880_2601\"><path fill=\"#fff\" transform=\"translate(.5)\" d=\"M0 0h122v122H0z\"/></clipPath><clipPath id=\"clip1_2880_2601\"><path fill=\"#fff\" transform=\"translate(13.572 47.057)\" d=\"M0 0h95.94v27.886H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "123",
      "height": "122",
      "viewBox": "0 0 123 122",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});