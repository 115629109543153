define("ember-svg-jar/inlined/range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>circle/white/delivery</title><g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#32313C\" cx=\"18\" cy=\"18\" r=\"18\"/><path d=\"M11 20v-7a2 2 0 012-2h7a2 2 0 012 2v9h-5a2 2 0 11-4 0h0a2 2 0 01-2-2z\" stroke=\"#FFF\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><circle stroke=\"#FFF\" stroke-linecap=\"square\" stroke-linejoin=\"round\" cx=\"15\" cy=\"22\" r=\"2\"/><path d=\"M22 22v-8h4l2 2v4a2 2 0 01-1.017 1.742A2 2 0 1123 22h0-1z\" stroke=\"#FFF\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><circle stroke=\"#FFF\" stroke-linecap=\"square\" stroke-linejoin=\"round\" cx=\"25\" cy=\"22\" r=\"2\"/><path stroke=\"#FFF\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M12 17H7M14 15H8M17 13H9\"/></g>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});