define("ember-svg-jar/inlined/location_outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 4a7 7 0 00-7 7c0 2.206 1.336 4.268 3.205 5.888l3.441 2.982a.54.54 0 00.708 0l3.441-2.982C17.664 15.268 19 13.206 19 11a7 7 0 00-7-7zm-9 7a9 9 0 0118 0c0 3.026-1.81 5.592-3.895 7.399l-3.441 2.983a2.54 2.54 0 01-3.328 0l-3.441-2.983C4.81 16.592 3 14.026 3 11z\" fill=\"currentColor\"/><path d=\"M14 11a2 2 0 11-4 0 2 2 0 014 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});