define("ember-svg-jar/inlined/notification-bell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>4DFAEE90-7273-499E-8E46-DFE79BFAA272</title><g fill=\"currentColor\" fill-rule=\"evenodd\"><path d=\"M7.5 0A1.5 1.5 0 019 1.5V2a1 1 0 01-1 1H7a1 1 0 01-1-1v-.5A1.5 1.5 0 017.5 0z\"/><path d=\"M12.7 14.25H2.3l1.083-1.875a.75.75 0 00.1-.375V7.767a4.017 4.017 0 118.034 0V12a.75.75 0 00.1.375L12.7 14.25zM1.983 11.799L.351 14.625A.75.75 0 001 15.75h13a.75.75 0 00.65-1.125l-1.633-2.826V7.767a5.517 5.517 0 00-11.034 0v4.032z\" fill-rule=\"nonzero\"/><path d=\"M8 18c1.105 0 2-1.078 2-2.409 0-1.33-.895 0-2 0s-2-1.33-2 0S6.895 18 8 18z\"/></g>",
    "attrs": {
      "width": "15",
      "height": "18",
      "viewBox": "0 0 15 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});