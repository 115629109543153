define("ember-svg-jar/inlined/car-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 4h6M19 1v6\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.662 11.005l3.227 2.09.513.274.01.005c.857.43 1.752.738 2.667.918l1.75.346.15.038c.55.178 1.014.799 1.08 1.633l.216 3.716-2.393.002v.99c0 1.3-.792 2.006-1.587 2.006-.795 0-1.587-.705-1.587-2.007v-.989H7.322l-.034 1.175c-.074 1.203-.824 1.82-1.581 1.82-.795 0-1.587-.704-1.587-2.006v-.587l-2.283-1.458-.057-.043a.401.401 0 01-.09-.294l.219-4.774.019-.192c.036-.237.119-.452.235-.628l.013-.02 1.488-2.503.005-.007h.023l.052-.01c1.74-.304 3.02-.463 3.862-.487l.254-.009c.719-.023 1.819-.06 3.017.036 1.355.108 2.742.38 3.785.965zM7.809 8.028l-.246.008c-.94.028-2.29.199-4.026.502-.38.028-.73.21-1.002.502l-.027.028-.163.208-1.53 2.574a3.746 3.746 0 00-.556 1.515l-.003.023-.029.291-.222 4.822c-.045.78.24 1.552.777 2.023l.023.02.168.127 1.494.955C2.696 23.56 3.994 25 5.707 25c1.599 0 2.84-1.238 3.181-2.995h8.227c.343 1.76 1.58 2.995 3.18 2.995s2.838-1.235 3.18-2.996l1.356-.002.044-.005c.647-.081 1.068-.706 1.12-1.354l.005-.046v-.199l-.26-4.27c-.126-1.597-1.046-2.968-2.348-3.37l-.018-.005-.227-.057-1.787-.353a8.993 8.993 0 01-2.276-.783l-.436-.232-3.21-2.08-.02-.011c-1.34-.76-2.995-1.055-4.426-1.17-1.285-.103-2.467-.063-3.183-.04z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "26",
      "height": "25",
      "viewBox": "0 0 26 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});