define("@ember-data/model/hooks", ["exports", "@ember-data/model/hooks-dXmQbIOF"], function (_exports, _hooksDXmQbIOF) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "buildSchema", {
    enumerable: true,
    get: function () {
      return _hooksDXmQbIOF.b;
    }
  });
  Object.defineProperty(_exports, "instantiateRecord", {
    enumerable: true,
    get: function () {
      return _hooksDXmQbIOF.i;
    }
  });
  Object.defineProperty(_exports, "modelFor", {
    enumerable: true,
    get: function () {
      return _hooksDXmQbIOF.m;
    }
  });
  Object.defineProperty(_exports, "teardownRecord", {
    enumerable: true,
    get: function () {
      return _hooksDXmQbIOF.t;
    }
  });
});