define("ember-svg-jar/inlined/checkmark-with-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M79.999 13.332c-36.82 0-66.667 29.848-66.667 66.667s29.848 66.666 66.667 66.666 66.666-29.847 66.666-66.666c0-36.82-29.847-66.667-66.666-66.667z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M104.709 61.945a6.668 6.668 0 010 9.428L78.042 98.04a6.667 6.667 0 01-9.428 0L55.281 84.706a6.667 6.667 0 019.428-9.428l8.62 8.62L95.28 61.944a6.667 6.667 0 019.428 0z\" fill=\"#009D68\"/>",
    "attrs": {
      "viewBox": "0 0 160 160",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});