define("snapchat-pixel/services/snapchat", ["exports", "@ember/service", "ember-get-config"], function (_exports, _service, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Snapchat extends _service.default {
    hasSnapChat() {
      return typeof window.snaptr !== 'undefined' || _emberGetConfig.default.snapChatPixel?.accountId;
    }
    initialize(email) {
      if (!this.hasSnapChat() || !_emberGetConfig.default.snapChatPixel?.accountId) {
        return;
      }
      window.snaptr('init', _emberGetConfig.default.snapChatPixel.accountId, {
        user_email: email
      });
      this.trackEvent('PAGE_VIEW');
    }
    trackEvent(event, eventData = {}) {
      if (!this.hasSnapChat()) {
        return;
      }
      window.snaptr('track', event, eventData);
    }
  }
  _exports.default = Snapchat;
});