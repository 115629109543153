define("@ember-data/serializer/transform", ["exports", "@ember-data/serializer/string-A02hFTMo"], function (_exports, _stringA02hFTMo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "BooleanTransform", {
    enumerable: true,
    get: function () {
      return _stringA02hFTMo.B;
    }
  });
  Object.defineProperty(_exports, "DateTransform", {
    enumerable: true,
    get: function () {
      return _stringA02hFTMo.D;
    }
  });
  Object.defineProperty(_exports, "NumberTransform", {
    enumerable: true,
    get: function () {
      return _stringA02hFTMo.N;
    }
  });
  Object.defineProperty(_exports, "StringTransform", {
    enumerable: true,
    get: function () {
      return _stringA02hFTMo.S;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringA02hFTMo.T;
    }
  });
});