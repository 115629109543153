define("ember-svg-jar/inlined/cross-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M.293.293a1 1 0 011.414 0L4 2.586 6.293.293a1 1 0 011.414 1.414L5.414 4l2.293 2.293a1 1 0 01-1.414 1.414L4 5.414 1.707 7.707A1 1 0 01.293 6.293L2.586 4 .293 1.707a1 1 0 010-1.414z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "8",
      "height": "8",
      "viewBox": "0 0 8 8",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});