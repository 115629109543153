define("ember-svg-jar/inlined/kilometer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>kilometer</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M7.5 0C10.538 0 13 2.493 13 5.57c0 1.901-1.577 4.743-4.732 8.526a1 1 0 01-1.536 0l-.291-.351C3.48 10.137 2 7.412 2 5.569 2 2.493 4.462 0 7.5 0zm0 3a2.5 2.5 0 100 5 2.5 2.5 0 000-5z\" fill=\"currentColor\"/><path stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-dasharray=\"2,3\" d=\"M1 20h13\"/></g>",
    "attrs": {
      "width": "14",
      "height": "21",
      "viewBox": "0 0 14 21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});