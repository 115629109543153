define("ember-svg-jar/inlined/credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2.667 45.333V18.667c0-4.534 3.467-8 8-8h42.667c4.533 0 8 3.466 8 8v26.666c0 4.534-3.467 8-8 8H10.667c-4.533 0-8-3.466-8-8zm53.334-26.666c0-1.6-1.067-2.667-2.667-2.667H10.667c-1.6 0-2.666 1.067-2.666 2.667v26.666C8 46.933 9.067 48 10.667 48h42.667c1.6 0 2.667-1.067 2.667-2.667V18.667z\" fill=\"currentColor\"/><path d=\"M17.455 26.182h17.454M17.455 37.818H29.09M17.455 32h26.181\" stroke=\"currentColor\" stroke-width=\"4.364\" stroke-linecap=\"round\"/>",
    "attrs": {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});