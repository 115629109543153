define("@adopted-ember-addons/ember-stripe-elements/utils/load-script", ["exports", "@ember/runloop", "rsvp"], function (_exports, _runloop, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = loadScript;
  /*
   * loadScript will load a JavaScript asset. Subsequent load
   * calls with a already requested URL will just chain off the
   * already existing promise.
   */

  let loadedScripts = {};
  function loadScript(url) {
    let promise = loadedScripts[url];
    if (!promise) {
      promise = new _rsvp.default.Promise((resolve, reject) => {
        let element = document.createElement('script');
        element.type = 'text/javascript';
        element.async = false;
        element.addEventListener('load', () => {
          (0, _runloop.run)(() => {
            resolve();
          });
        }, false);
        element.addEventListener('error', () => {
          let error = new Error(`Could not load script ${url}`);
          (0, _runloop.run)(() => {
            reject(error);
          });
        }, false);
        element.src = url;
        let firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode.insertBefore(element, firstScript);
      });
      loadedScripts[url] = promise;
    }
    return promise;
  }
});