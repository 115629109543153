define("@ember/service/index", ["exports", "@ember/object/-internals", "@ember/-internals/metal"], function (_exports, _internals, _metal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.inject = inject;
  _exports.service = service;
  function inject(...args) {
    return (0, _metal.inject)('service', ...args);
  }
  function service(...args) {
    return (0, _metal.inject)('service', ...args);
  }
  /**
    @class Service
    @extends EmberObject
    @since 1.10.0
    @public
  */
  class Service extends _internals.FrameworkObject {}
  Service.isServiceFactory = true;
  var _default = _exports.default = Service;
});