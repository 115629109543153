define("ember-svg-jar/inlined/transmission_updated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 10a2 2 0 100-4 2 2 0 000 4zM15.386 10.357a.75.75 0 01.257 1.029l-2.382 3.97c1.948.3 3.435 1.19 4.474 2.072.637.54 1.11 1.08 1.425 1.488a8.106 8.106 0 01.48.69l.007.014.003.005v.002l-.622.357.623-.356a.75.75 0 01-1.301.746v-.001L19 20l-.65.373-.002-.003-.014-.024a6.63 6.63 0 00-.36-.512 8.48 8.48 0 00-1.21-1.262c-1.08-.917-2.7-1.822-4.907-1.822-2.202 0-3.745.901-4.747 1.807a7.54 7.54 0 00-1.108 1.25 5.902 5.902 0 00-.318.505l-.012.022-.001.001a.75.75 0 01-1.342-.67L5 20l-.67-.335v-.002l.001-.002.002-.005.008-.013a3.067 3.067 0 01.108-.194c.073-.126.181-.3.326-.505a9.05 9.05 0 011.33-1.5 8.389 8.389 0 015.468-2.19l2.784-4.64a.75.75 0 011.029-.257z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});