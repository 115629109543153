define("ember-svg-jar/inlined/location_fill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 9a9 9 0 0118 0c0 3.026-1.81 5.592-3.895 7.399l-3.441 2.983a2.54 2.54 0 01-3.328 0l-3.441-2.983C1.81 14.592 0 12.026 0 9zm9 2a2 2 0 100-4 2 2 0 000 4z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 18 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});