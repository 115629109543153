define("ember-svg-jar/inlined/transmission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.5 5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z\" fill=\"currentColor\"/><path d=\"M7.5 2.5l-1 9\" stroke=\"currentColor\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M1.063 14c2-1.333 4-2 6-2s4 .667 6 2\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "14",
      "height": "15",
      "viewBox": "0 0 14 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});