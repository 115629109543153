define("ember-svg-jar/inlined/car-clean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.998 16a2 2 0 11-4 0H2.96l-1.655-.002a.333.333 0 01-.305-.36l.211-2.068c.084-.822.73-1.485 1.572-1.613l1.455-.22a9.741 9.741 0 002.427-.7L9.313 9.72c1.961-.854 5.018-.722 6.141-.697.736.017 1.823.122 3.262.316.195.004.38.09.505.236l1.24 1.604c.207.238.33.534.353.844l.182 2.43a.82.82 0 01-.459.793L18.998 16a2 2 0 11-4 0h-8z\" stroke=\"currentColor\"/><path d=\"M16.998 18a2 2 0 100-4 2 2 0 000 4zM4.998 18a2 2 0 100-4 2 2 0 000 4z\" stroke=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.5 7C7.328 7 8 6.273 8 5.376 8 4.778 7.5 3.986 6.5 3 5.5 3.986 5 4.778 5 5.376 5 6.273 5.672 7 6.5 7zM11.5 5c.828 0 1.5-.727 1.5-1.624 0-.598-.5-1.39-1.5-2.376-1 .986-1.5 1.778-1.5 2.376C10 4.273 10.672 5 11.5 5zM16.5 6c.828 0 1.5-.727 1.5-1.624 0-.598-.5-1.39-1.5-2.376-1 .986-1.5 1.778-1.5 2.376C15 5.273 15.672 6 16.5 6z\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "22",
      "height": "19",
      "viewBox": "0 0 22 19",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});