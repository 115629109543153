define("@ember-data/tracking/index", ["exports", "@ember/debug", "@glimmer/tracking/primitives/cache", "@ember-data/tracking/-private", "@ember/object/compat"], function (_exports, _debug, _cache, _private, _compat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cached = cached;
  Object.defineProperty(_exports, "compat", {
    enumerable: true,
    get: function () {
      return _compat.dependentKeyCompat;
    }
  });
  Object.defineProperty(_exports, "createCache", {
    enumerable: true,
    get: function () {
      return _cache.createCache;
    }
  });
  Object.defineProperty(_exports, "getValue", {
    enumerable: true,
    get: function () {
      return _cache.getValue;
    }
  });
  Object.defineProperty(_exports, "memoTransact", {
    enumerable: true,
    get: function () {
      return _private.memoTransact;
    }
  });
  Object.defineProperty(_exports, "transact", {
    enumerable: true,
    get: function () {
      return _private.transact;
    }
  });
  Object.defineProperty(_exports, "untracked", {
    enumerable: true,
    get: function () {
      return _private.untracked;
    }
  });
  function cached(target, key, descriptor) {
    // Error on `@cached()`, `@cached(...args)`, and `@cached propName = value;`
    (false && !(target !== undefined) && (0, _debug.assert)('You attempted to use @cached(), which is not necessary nor supported. Remove the parentheses and you will be good to go!', target !== undefined));
    (false && !(typeof target === 'object' && typeof key === 'string' && typeof descriptor === 'object' && arguments.length === 3) && (0, _debug.assert)(`You attempted to use @cached on with ${arguments.length > 1 ? 'arguments' : 'an argument'} ( @cached(${Array.from(arguments).map(d => `'${d}'`).join(', ')}), which is not supported. Dependencies are automatically tracked, so you can just use ${'`@cached`'}`, typeof target === 'object' && typeof key === 'string' && typeof descriptor === 'object' && arguments.length === 3));
    (false && !(typeof descriptor.get === 'function') && (0, _debug.assert)(`The @cached decorator must be applied to getters. '${key}' is not a getter.`, typeof descriptor.get === 'function'));
    const caches = new WeakMap();
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const getter = descriptor.get;
    descriptor.get = function () {
      if (!caches.has(this)) caches.set(this, (0, _cache.createCache)(getter.bind(this)));
      return (0, _cache.getValue)(caches.get(this));
    };
  }
});