define("ember-svg-jar/inlined/download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\"14\" y=\"8.854\" width=\"5.765\" height=\"1.647\" rx=\".824\" transform=\"rotate(90 14 8.854)\" fill=\"currentColor\"/><rect x=\"14\" y=\"14.618\" width=\"14\" height=\"1.647\" rx=\".824\" transform=\"rotate(-180 14 14.618)\" fill=\"currentColor\"/><rect x=\"1.647\" y=\"8.854\" width=\"5.765\" height=\"1.647\" rx=\".824\" transform=\"rotate(90 1.647 8.854)\" fill=\"currentColor\"/><path d=\"M6.177.824v8.235a.824.824 0 101.647 0V.824a.824.824 0 00-1.647 0z\" fill=\"currentColor\"/><path d=\"M2.612 5.836l3.494 3.493a.824.824 0 001.165-1.164L3.776 4.67a.824.824 0 00-1.164 1.165z\" fill=\"currentColor\"/><path d=\"M10.223 4.67L6.73 8.165A.824.824 0 007.895 9.33l3.493-3.495a.824.824 0 00-1.165-1.165z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "14",
      "height": "15",
      "viewBox": "0 0 14 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});