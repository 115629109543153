define("ember-svg-jar/inlined/calendar-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4 1h10a3 3 0 013 3v10a3 3 0 01-3 3H4a3 3 0 01-3-3V4a3 3 0 013-3z\" stroke=\"currentColor\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4 1h10a3 3 0 013 3v1.364H1V4a3 3 0 013-3z\" stroke=\"currentColor\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path d=\"M4 13h1M4 9h1M9 13h1M13 9h1\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});