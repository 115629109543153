define("ember-svg-jar/inlined/safari-pinned-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M610.5 197.6c-36.9 3.7-66.2 13.2-95.4 30.6-29.3 17.7-57.4 46-74.8 75.5-42.1 71.7-39.7 161.9 6.2 230.8 22.4 33.6 50.6 58.1 87.6 75.9l12.7 6.1-38.9 91c-21.4 50.1-38.9 92.2-38.9 93.6 0 1.4.8 3.3 1.8 4.2 1.7 1.6 14 1.7 156.9 1.7 148.9 0 155.1-.1 156.6-1.8 1-1 1.7-2.9 1.7-4.3 0-1.3-17.5-43.3-38.9-93.4l-38.9-91 12.7-6.1c37-17.8 65.2-42.3 87.6-75.9 45.9-68.9 48.3-159.1 6.2-230.8-33-56-88.7-93.5-154.7-104.2-9.2-1.5-41.3-2.7-49.5-1.9zM282.5 450.6c-32.2 5.2-59.5 25.3-73.5 53.9-2.7 5.5-5.9 13.6-7.2 18l-2.3 8-.3 136.4c-.2 126.4-.1 136.5 1.4 138.2 1.6 1.8 5.5 1.9 96 1.9H391l1.5-2.4c1.4-2.1 1.5-16.9 1.3-137.7l-.3-135.4-2.2-7.6c-5.3-18.8-14.1-33.7-27.5-46.4-13.2-12.7-23.8-18.8-41.5-24.1-7.3-2.1-11.3-2.7-22.8-2.9-7.7-.2-15.3-.1-17 .1zm79.4 134c2.2 1.6 2.3 2.2 1.8 8.4-.3 3.9-1.9 10.1-3.6 14.6-13.4 34.6-51.2 52.1-86.4 39.9-10.2-3.5-17.3-8.1-25.9-16.7-6.3-6.3-8.6-9.6-12.2-17-4.4-9.2-8.3-23.7-7-26.1 2.8-5-1.2-4.7 67.3-4.7 57.5 0 63.9.2 66 1.6z\"/>",
    "attrs": {
      "version": "1.0",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "1365.333",
      "height": "1365.333",
      "viewBox": "0 0 1024 1024"
    }
  };
});