define("ember-svg-jar/inlined/arrow-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M.293.293a1 1 0 011.414 0L7 5.586 12.293.293a1 1 0 111.414 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "14",
      "height": "8",
      "viewBox": "0 0 14 8",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});