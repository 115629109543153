define("ember-svg-jar/inlined/exclamation_triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M62.11 31.063c7.37-14.745 28.412-14.745 35.781 0l39.979 79.996c6.646 13.297-3.024 28.941-17.89 28.941H40.02c-14.865 0-24.535-15.644-17.89-28.942l39.98-79.995z\" fill=\"#FFF6C8\"/><path d=\"M80 53.334A6.667 6.667 0 0073.333 60v26.667a6.667 6.667 0 0013.333 0V60A6.667 6.667 0 0080 53.334zM80 100a6.667 6.667 0 100 13.334A6.667 6.667 0 0080 100z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 160 160",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});