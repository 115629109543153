define("ember-svg-jar/inlined/wash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19 6a1 1 0 11-2 0 1 1 0 012 0zm2 0a3 3 0 11-6 0 3 3 0 016 0zm0 13a2 2 0 11-4 0 2 2 0 014 0zM14 13.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm2 0a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zM9 14a1 1 0 011-1 1 1 0 100-2 3 3 0 00-3 3 1 1 0 102 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});