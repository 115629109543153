define("@ember-data/serializer/string-A02hFTMo", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.T = _exports.S = _exports.N = _exports.D = _exports.B = void 0;
  /**
    @module @ember-data/serializer
  */

  /**
    The `Transform` class is used to serialize and deserialize model
    attributes when they are saved or loaded from an
    adapter. Subclassing `Transform` is useful for creating custom
    attributes. All subclasses of `Transform` must implement a
    `serialize` and a `deserialize` method.
  
    Example
  
    ```app/transforms/temperature.js
  
    // Converts centigrade in the JSON to fahrenheit in the app
    export default class TemperatureTransform {
      deserialize(serialized, options) {
        return (serialized *  1.8) + 32;
      }
  
      serialize(deserialized, options) {
        return (deserialized - 32) / 1.8;
      }
  
      static create() {
        return new this();
      }
    }
    ```
  
    Usage
  
    ```app/models/requirement.js
    import Model, { attr } from '@ember-data/model';
  
    export default class RequirementModel extends Model {
      @attr('string') name;
      @attr('temperature') temperature;
    }
    ```
  
    The options passed into the `attr` function when the attribute is
    declared on the model is also available in the transform.
  
    ```app/models/post.js
    import Model, { attr } from '@ember-data/model';
  
    export default class PostModel extends Model {
      @attr('string') title;
      @attr('markdown', {
        markdown: {
          gfm: false,
          sanitize: true
        }
      })
      markdown;
    }
    ```
  
    ```app/transforms/markdown.js
    export default class MarkdownTransform {
      serialize(deserialized, options) {
        return deserialized.raw;
      }
  
      deserialize(serialized, options) {
        let markdownOptions = options.markdown || {};
  
        return marked(serialized, markdownOptions);
      }
  
      static create() {
        return new this();
      }
    }
    ```
  
    @class Transform
    @public
   */
  /**
    When given a deserialized value from a record attribute this
    method must return the serialized value.
  
    Example
  
    ```javascript
    serialize(deserialized, options) {
      return deserialized ? null : Number(deserialized);
    }
    ```
  
    @method serialize
    @public
    @param deserialized The deserialized value
    @param options hash of options passed to `attr`
    @return The serialized value
  */
  /**
    When given a serialized value from a JSON object this method must
    return the deserialized value for the record attribute.
  
    Example
  
    ```javascript
    deserialize(serialized, options) {
      return empty(serialized) ? null : Number(serialized);
    }
    ```
  
    @method deserialize
    @public
    @param serialized The serialized value
    @param options hash of options passed to `attr`
    @return The deserialized value
  */

  const Transform = _exports.T = _object.default;

  /**
    @module @ember-data/serializer
  */

  /**
    The `BooleanTransform` class is used to serialize and deserialize
    boolean attributes on Ember Data record objects. This transform is
    used when `boolean` is passed as the type parameter to the
    [attr](/ember-data/release/functions/@ember-data%2Fmodel/attr) function.
  
    Usage
  
    ```app/models/user.js
    import Model, { attr } from '@ember-data/model';
  
    export default class UserModel extends Model {
      @attr('boolean') isAdmin;
      @attr('string') name;
      @attr('string') email;
    }
    ```
  
    By default, the boolean transform only allows for values of `true` or
    `false`. You can opt into allowing `null` values for
    boolean attributes via `attr('boolean', { allowNull: true })`
  
    ```app/models/user.js
    import Model, { attr } from '@ember-data/model';
  
    export default class UserModel extends Model {
      @attr('string') email;
      @attr('string') username;
      @attr('boolean', { allowNull: true }) wantsWeeklyEmail;
    }
    ```
  
    @class BooleanTransform
    @public
   */
  class BooleanTransform {
    deserialize(serialized, options) {
      if ((serialized === null || serialized === undefined) && options?.allowNull === true) {
        return null;
      }
      if (typeof serialized === 'boolean') {
        return serialized;
      } else if (typeof serialized === 'string') {
        return /^(true|t|1)$/i.test(serialized);
      } else if (typeof serialized === 'number') {
        return serialized === 1;
      } else {
        return false;
      }
    }
    serialize(deserialized, options) {
      if ((deserialized === null || deserialized === undefined) && options?.allowNull === true) {
        return null;
      }
      return Boolean(deserialized);
    }
    static create() {
      return new this();
    }
  }

  /**
    @module @ember-data/serializer
  */

  /**
   The `DateTransform` class is used to serialize and deserialize
   date attributes on Ember Data record objects. This transform is used
   when `date` is passed as the type parameter to the
   [attr](/ember-data/release/functions/@ember-data%2Fmodel/attr) function. It uses the [`ISO 8601`](https://en.wikipedia.org/wiki/ISO_8601)
   standard.
  
   ```app/models/score.js
   import Model, { attr, belongsTo } from '@ember-data/model';
  
   export default class ScoreModel extends Model {
      @attr('number') value;
      @belongsTo('player') player;
      @attr('date') date;
    }
   ```
  
   @class DateTransform
    @public
   */
  _exports.B = BooleanTransform;
  class DateTransform {
    deserialize(serialized, _options) {
      if (typeof serialized === 'string') {
        let offset = serialized.indexOf('+');
        if (offset !== -1 && serialized.length - 5 === offset) {
          offset += 3;
          return new Date(serialized.slice(0, offset) + ':' + serialized.slice(offset));
        }
        return new Date(serialized);
      } else if (typeof serialized === 'number') {
        return new Date(serialized);
      } else if (serialized === null || serialized === undefined) {
        // if the value is null return null
        // if the value is not present in the data return undefined
        return serialized;
      } else {
        return null;
      }
    }
    serialize(date, _options) {
      // @ts-expect-error isNaN accepts date as it is coercible
      if (date instanceof Date && !isNaN(date)) {
        return date.toISOString();
      } else {
        return null;
      }
    }
    static create() {
      return new this();
    }
  }

  /**
    @module @ember-data/serializer
  */
  _exports.D = DateTransform;
  function isNumber(value) {
    return value === value && value !== Infinity && value !== -Infinity;
  }

  /**
    The `NumberTransform` class is used to serialize and deserialize
    numeric attributes on Ember Data record objects. This transform is
    used when `number` is passed as the type parameter to the
    [attr](/ember-data/release/functions/@ember-data%2Fmodel/attr) function.
  
    Usage
  
    ```app/models/score.js
    import Model, { attr, belongsTo } from '@ember-data/model';
  
    export default class ScoreModel extends Model {
      @attr('number') value;
      @belongsTo('player') player;
      @attr('date') date;
    }
    ```
  
    @class NumberTransform
    @public
   */
  class NumberTransform {
    deserialize(serialized, _options) {
      if (serialized === '' || serialized === null || serialized === undefined) {
        return null;
      } else {
        const transformed = Number(serialized);
        return isNumber(transformed) ? transformed : null;
      }
    }
    serialize(deserialized, _options) {
      if (deserialized === '' || deserialized === null || deserialized === undefined) {
        return null;
      } else {
        const transformed = Number(deserialized);
        return isNumber(transformed) ? transformed : null;
      }
    }
    static create() {
      return new this();
    }
  }

  /**
    @module @ember-data/serializer
  */

  /**
    The `StringTransform` class is used to serialize and deserialize
    string attributes on Ember Data record objects. This transform is
    used when `string` is passed as the type parameter to the
    [attr](/ember-data/release/functions/@ember-data%2Fmodel/attr) function.
  
    Usage
  
    ```app/models/user.js
    import Model, { attr, belongsTo } from '@ember-data/model';
  
    export default class UserModel extends Model {
      @attr('boolean') isAdmin;
      @attr('string') name;
      @attr('string') email;
    }
    ```
  
    @class StringTransform
    @public
   */
  _exports.N = NumberTransform;
  class StringTransform {
    deserialize(serialized, _options) {
      return !serialized && serialized !== '' ? null : String(serialized);
    }
    serialize(deserialized, _options) {
      return !deserialized && deserialized !== '' ? null : String(deserialized);
    }
    static create() {
      return new this();
    }
  }
  _exports.S = StringTransform;
});