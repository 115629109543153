define("ember-svg-jar/inlined/wallet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke=\"currentColor\" stroke-linejoin=\"round\" transform=\"translate(1)\"><path d=\"M11 9h5v3h-5a1 1 0 01-1-1v-1a1 1 0 011-1zM9 2L8 0 3 5l8-4 2 4\" stroke-linecap=\"round\"/><rect height=\"10\" rx=\"1\" stroke-linecap=\"square\" width=\"16\" y=\"5\"/></g>",
    "attrs": {
      "height": "16",
      "viewBox": "0 0 18 16",
      "width": "18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});