define("@ember-data/serializer/-private", ["exports", "@ember-data/serializer/embedded-records-mixin-HTw8JJT1", "@ember-data/serializer/string-A02hFTMo", "@ember-data/serializer/utils-N1ERF6HN"], function (_exports, _embeddedRecordsMixinHTw8JJT, _stringA02hFTMo, _utilsN1ERF6HN) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "BooleanTransform", {
    enumerable: true,
    get: function () {
      return _stringA02hFTMo.B;
    }
  });
  Object.defineProperty(_exports, "DateTransform", {
    enumerable: true,
    get: function () {
      return _stringA02hFTMo.D;
    }
  });
  Object.defineProperty(_exports, "EmbeddedRecordsMixin", {
    enumerable: true,
    get: function () {
      return _embeddedRecordsMixinHTw8JJT.e;
    }
  });
  Object.defineProperty(_exports, "NumberTransform", {
    enumerable: true,
    get: function () {
      return _stringA02hFTMo.N;
    }
  });
  Object.defineProperty(_exports, "StringTransform", {
    enumerable: true,
    get: function () {
      return _stringA02hFTMo.S;
    }
  });
  Object.defineProperty(_exports, "Transform", {
    enumerable: true,
    get: function () {
      return _stringA02hFTMo.T;
    }
  });
  Object.defineProperty(_exports, "coerceId", {
    enumerable: true,
    get: function () {
      return _utilsN1ERF6HN.c;
    }
  });
});