define("ember-svg-jar/inlined/happy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M101.234 77.663c8.844-3.475 16.18-9.87 20.747-18.082a39.661 39.661 0 004.279-26.924c-1.801-9.19-6.804-17.478-14.148-23.437C104.768 3.26 95.54 0 86.015 0 76.49 0 67.263 3.26 59.92 9.22c-7.345 5.96-12.347 14.246-14.148 23.437A39.661 39.661 0 0050.05 59.58c4.567 8.213 11.902 14.607 20.746 18.082l-14.638 33.66a1.186 1.186 0 00.098 1.129 1.239 1.239 0 001.01.548h57.499c.203 0 .403-.049.581-.143.179-.095.331-.231.442-.397.112-.167.18-.358.198-.556a1.195 1.195 0 00-.095-.581l-14.657-33.66z\" fill=\"#FF4A7E\"/><path d=\"M52.318 43.495c-1.835-4.44-5.395-7.983-9.896-9.851a18.773 18.773 0 00-14.104-.107c-4.53 1.799-8.145 5.287-10.05 9.699a17.732 17.732 0 00-.11 13.824c2.034 4.92 1.585 6.454-3.182 14.938l-.829-10.934a6.895 6.895 0 00-2.43-4.75 7.175 7.175 0 00-5.144-1.674 7.185 7.185 0 00-2.666.717 7.059 7.059 0 00-2.182 1.663A6.902 6.902 0 00.36 59.374a6.807 6.807 0 00-.341 2.688l1.228 16.775a6.891 6.891 0 002.166 4.535 7.162 7.162 0 004.714 1.931A68.814 68.814 0 002.408 113h36.857c0-9.423 3.31-15.275 7.494-22.687 6.087-10.747 14.38-25.48 5.559-46.818zM105.023 46.092a1.252 1.252 0 01.901.381 1.182 1.182 0 01.328.905c-.335 5.025-2.613 9.737-6.37 13.18-3.759 3.443-8.716 5.359-13.866 5.359S75.91 64 72.151 60.557c-3.758-3.442-6.035-8.154-6.37-13.179a1.177 1.177 0 01.327-.905 1.23 1.23 0 01.901-.38h38.014z\" fill=\"#29194A\"/><path d=\"M47.531 49.146a1.225 1.225 0 011.37.352c.109.128.189.277.236.438.831 3.024.5 6.247-.93 9.044a12.73 12.73 0 01-6.807 6.093 12.879 12.879 0 01-9.174-.002 12.73 12.73 0 01-6.805-6.096 1.198 1.198 0 01.657-1.651l21.453-8.178z\" fill=\"#fff\"/><path d=\"M104.43 89.245c2.765 2.709 7.248 2.71 10.012.002a6.835 6.835 0 00-.002-9.807l-2.881-2.822c-2.766-2.71-7.248-2.71-10.012-.003a6.834 6.834 0 00.002 9.808l2.881 2.822z\" fill=\"#29194A\"/>",
    "attrs": {
      "width": "127",
      "height": "113",
      "viewBox": "0 0 127 113",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});