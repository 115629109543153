define("ember-svg-jar/inlined/triangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M62.113 31.064c7.37-14.745 28.411-14.745 35.78 0l39.98 79.995c6.645 13.298-3.025 28.941-17.891 28.941H40.023c-14.866 0-24.536-15.643-17.89-28.941l39.98-79.995z\" fill=\"#FFF6C8\"/><path d=\"M80.006 53.334a6.667 6.667 0 00-6.667 6.667v26.666a6.667 6.667 0 1013.334 0V60.001a6.667 6.667 0 00-6.667-6.667zM80.006 100.001a6.666 6.666 0 100 13.333 6.667 6.667 0 000-13.333z\" fill=\"#000\"/>",
    "attrs": {
      "width": "160",
      "height": "160",
      "viewBox": "0 0 160 160",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});